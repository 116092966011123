define(["siteObj", "cookie", "$window"], function (siteObj, cookie, $window) {
  const qubitProductListPageBadging = () => {
    const component = {};
    const language = siteObj.siteDefaultLocale.replace(/_/g, "-");
    const siteUrl = $window.location.host;
    const qubitCookieName = '_qubitTracker';
    const viewWindowSize = 1440;
    let attempts = 0;
    const schemaTimeframes = {
      'in the last hour': 60,
      'in the last day': 1440,
      'in the last 2 days': 2880,
      'in the last 3 days': 4320,
      'in the last week': 10080
    }

    component.config = {
      selectors: {
        productId: '[data-product-id]',
        productList: '.productListProducts_product',
        productBadge: '[data-product-badge]',
        productBadgingBlock: '[data-qubit-block]',
        productBadgingContainer: '[data-qubit-container]',
        productBadginglink: '[data-qubit-block] a'
      },
      urls: {
        qubitUrl: 'https://api.qubit.com/placements/query',
        qubitTallyUrl: 'https://tally-1.qubitproducts.com/tally',
        qubitTrackingUrl: 'https://api.qubit.com/placements/cb?'
      }
    };

    component.init = () => {
      component.placemnetId = siteObj.config.qubitProductListPageBadgingPlacementId;
      component.trackingId = siteObj.config.qubitTrackingId;

      try {
        if (
          cookie.get('actualOptanonConsent') && cookie.get('actualOptanonConsent').includes('C0002') ||
          $window.cookieName === 'actualOptanonConsent' && $window.cookieValue.includes('C0002')
        ) {
          if (component.placemnetId && component.trackingId) {
            component.makeRequest(component.placemnetId, component.trackingId);
          }
        } else {
          component.getCookieConsent();
        }
      } catch (error) {
        console.log('OneTrust not Found')
      }

    };

    component.makeRequest = (placementId, trackingId) => {
      let getVisitorId = '';
      let placementMode = 'LIVE';
      let queryString = $window.location.search;
      let queryHash = $window.location.hash;
      let queryParams = (queryString) ? queryString : queryHash;
      const urlPreviewParams = (queryParams) ? new URLSearchParams(queryParams) : null;
      const getPreviewMode = (urlPreviewParams) ? urlPreviewParams.get('qb_campaign_id') : null;
      const getSampleMode = (urlPreviewParams) ? urlPreviewParams.get('qb_mode') : null;

      if (getPreviewMode || getSampleMode) {
        let getQbplacementId = urlPreviewParams.get('qb_placement_id');
        placementId = getQbplacementId;

        if (getPreviewMode) {
          placementMode = 'PREVIEW';
        }
        if (getSampleMode) {
          placementMode = getSampleMode;
        }
      }

      if (cookie.get(qubitCookieName)) {
        getVisitorId = cookie.get(qubitCookieName);
      };

      const qubitQuery = `query PlacementContent (
        $mode: Mode!
        $placementId: String!
        $previewOptions: PreviewOptions
        $attributes: Attributes!
        $resolveVisitorState: Boolean!
      ) {
        placementContent(
          mode: $mode
          placementId: $placementId
          previewOptions: $previewOptions
          attributes: $attributes
          resolveVisitorState: $resolveVisitorState
        ) {
          content
          callbackData
          visitorId
        }
      }`;

      const qubitVariables = {
        mode: placementMode,
        placementId: placementId,
        resolveVisitorState: true,
        attributes: {
          visitor: {
            id: getVisitorId,
            url: siteObj.siteURL,
          },
          user: {},
          product: {},
          view: {
            currency: siteObj.currencyType,
            type: 'category',
            subtypes: [],
            language: language,
          },
        }
      }

      if (getPreviewMode) {
        const getQbcampaignId = urlPreviewParams.get('qb_campaign_id');
        const getQbgroup = urlPreviewParams.get('qb_group');
        const getQbexperienceId = urlPreviewParams.get('qb_experience_id');
        let previewOptions = { campaignId: getQbcampaignId, group: getQbgroup, experienceId: getQbexperienceId }
        qubitVariables['previewOptions'] = previewOptions;
      }

      component.getQubitData(qubitQuery, qubitVariables).then((response) => {
        if (!response.data.placementContent) {
          return;
        }
        const items = response.data.placementContent.content;
        const callbackData = response.data.placementContent.callbackData;
        const setVisitorId = response.data.placementContent.visitorId;

        component.setCookie(setVisitorId);
        component.impression(callbackData);

        if (!siteObj.experiments.some(experiment => experiment.name === "qubit_pdp_badging" && experiment.value !== "v2|plp_badging")) {
          // If we have content from a Campaign
          if (items) {
            if (!items.config) {
              return;
            }
            const matches = [];
            const counter = items.config.counter.toLowerCase()
            const timeframe = counter === 'views' ? viewWindowSize : schemaTimeframes[items.config.timeframe.toLowerCase()];

            component.getProductId().map((productId, index, arr) => {
              component.getQubitTallyData(productId, trackingId, timeframe, counter).then((response) => {
                if (response.data) {
                  if (component.checkThreshold(items.config.op, items.config.threshold, response.data)) {
                    matches.push({
                      productId: productId,
                      count: response.data
                    });
                  }
                }
                if (arr.length - 1 === index) {
                  let sortMatches = matches.sort((a, b) => Number(b.count) - Number(a.count));
                  Object.entries(sortMatches).slice(0, items.config.max).map((match) => {
                    component.createProductBadge(items, match[1].productId);
                  });
                  component.productBadgeClick();
                }
              });
            });
          }
        }
      });
    };

    // Get qubit product badging data
    component.getQubitData = (query, variables) => {
      return new Promise(resolve => {
        fetch(component.config.urls.qubitUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'text/plain' },
          body: JSON.stringify({
            query: query,
            variables: variables,
          }),
        })
          .then((res) => { return resolve(res.json()) })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    // Get qubit tally data
    component.getQubitTallyData = (productId, trackingId, timeframe, counter) => {
      return new Promise(resolve => {
        fetch(`${component.config.urls.qubitTallyUrl}/${trackingId}/ecount/atom-${counter}-${timeframe}min/${productId}?cache=true`)
          .then((res) => { return resolve(res.json()) })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    // Get thershold match
    component.checkThreshold = (op, threshold, count) => {
      return op === 'More than' ? count >= threshold : count <= threshold;
    }

    // Get product id
    component.getProductId = () => {
      const products = document.querySelectorAll(component.config.selectors.productId);
      const productIds = [];
      products.forEach((product) => {
        let id = product.dataset.productId;
        if (productIds.indexOf(id) === -1) {
          productIds.push(id);
        }
      });
      return productIds;
    }

    // Product list badging markup
    component.createProductBadge = (item, id) => {
      const product = document.querySelector(`[data-product-id="${id}"]`);
      const productBlock = product.closest(component.config.selectors.productBadgingBlock);
      const productBadgingContainer = productBlock.querySelector(component.config.selectors.productBadgingContainer);

      const markup = `
        <div class="qubit-plp-badging-container" data-product-badge>
          ${(item.content.icon) ? `<img src="${item.content.icon}" class="qubit-plp-img" alt="${item.content.message}" />` : ''}
          <span class="qubit-plp-message">
            ${item.content.message}
          </span>
        </div>
      `;

      return productBadgingContainer.insertAdjacentHTML('beforeend', markup);
    }

    // Product badging click event
    component.productBadgeClick = () => {
      const productBadge = document.querySelectorAll(component.config.selectors.productBadge);
      productBadge.forEach(event => {
        event.addEventListener('click', (e) => {
          const productBlock = e.target.closest(component.config.selectors.productBadgingBlock);
          const productBadginglink = productBlock.querySelector(component.config.selectors.productBadginglink);
          productBadginglink.click();
        });
      });
    }

    // Qubit tracking impression
    component.impression = (callbackData) => {
      const products = [];
      const impressions = document.querySelectorAll(component.config.selectors.productList);

      var observer = new IntersectionObserver(function (entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting === true) {
            const productList = entry.target.querySelector(component.config.selectors.productId)
            const productId = productList.dataset.productId;;
            if (products.indexOf(productId) === -1 && component.gaClientId()) {
              products.push(productId);
              component.impressionTracking(callbackData, products, productId)
              observer.unobserve(entry.target)
            }
          }
        });
      }, { threshold: [0.1] });

      impressions.forEach((impression) => {
        observer.observe(impression);
      });
    }

    component.impressionTracking = async (callbackData, products, productId) => {
      let params = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        debug: "qp"
      });

      let paramsWithId = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        p: productId,
        debug: "qp"
      });

      let promises = [
        (products.length === 1) ? fetch(component.config.urls.qubitTrackingUrl + params) : '',
        fetch(component.config.urls.qubitTrackingUrl + paramsWithId)
      ];

      await Promise.allSettled(promises).then((responses) => {
        responses.forEach((response) => {
          if (response.ok) {
            console.log('success');
          }
        });
      }).catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    };

    // Get GA client ID
    component.gaClientId = () => {
      try {
        var tracker = ga.getAll()[0];
        return tracker.get('clientId');
      }
      catch (e) {
        return;
      }
    }

    //Get cookie consent
    component.getCookieConsent = () => {
      let bannerAcceptButton = document.getElementById('onetrust-accept-btn-handler');
      let pcAllowAllButton = document.getElementById('accept-recommended-btn-handler');
      let pcSaveButton = document.getElementsByClassName('save-preference-btn-handler onetrust-close-btn-handler')[0];

      if (!bannerAcceptButton && !pcAllowAllButton && !pcSaveButton) {
        attempts += 1;
        if (attempts > 30) {
          return;
        }
        setTimeout(component.getCookieConsent, 100);
      } else {
        if (bannerAcceptButton) {
          bannerAcceptButton.addEventListener('click', (e) => { component.init(); });
        }
        if (pcAllowAllButton) {
          pcAllowAllButton.addEventListener('click', (e) => { component.init(); });
        }
        if (pcSaveButton) {
          pcSaveButton.addEventListener('click', (e) => { component.init(); });
        }
        return;
      }
    }

    //Qubit tracker cookie
    component.setCookie = (cookieValue) => {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const path = '/';
      let domain;
      if ($window.location.host.indexOf('www') === 0) {
        domain = siteUrl.replace('www', '');
      } else {
        domain = `.${siteUrl}`;
      }
      if (!cookie.get(qubitCookieName)) {
        document.cookie = `${qubitCookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; domain=${domain}; path=${path};`;
      }
    }

    return component;
  };

  return qubitProductListPageBadging;
});
